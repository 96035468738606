export const HomeIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
  <svg width='17.996' height='20.442' viewBox='0 0 17.996 20.442' {...props}>
    <g transform='translate(-30.619 0.236)'>
      <path
        d='M48.187,7.823,39.851.182A.7.7,0,0,0,38.9.2L31.03,7.841a.7.7,0,0,0-.211.5V19.311a.694.694,0,0,0,.694.694H37.3A.694.694,0,0,0,38,19.311V14.217h3.242v5.095a.694.694,0,0,0,.694.694h5.789a.694.694,0,0,0,.694-.694V8.335a.7.7,0,0,0-.228-.512ZM47.023,18.617h-4.4V13.522a.694.694,0,0,0-.694-.694H37.3a.694.694,0,0,0-.694.694v5.095H32.2V8.63l7.192-6.98L47.02,8.642v9.975Z'
        transform='translate(0 0)'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.4'
      />
    </g>
  </svg>
);
