import type { ApolloCache, NormalizedCache } from '@apollo/client';
import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import type { DeepPartial } from 'react-hook-form';

import type {
  AddItemMutation,
  AddPaymentToOrderMutation,
  ApplyCouponMutation,
  CartMetaQuery,
  CartQuery,
  CreateAddressInput,
  CreateCustomerInput,
  CreateGuestCustomerMutation,
  ErrorResult,
  Order,
  OrderLine,
  PaymentInput,
  RemoveOrderLineMutation,
  SetBillingAddressMutation,
  SetOrderShippingMutation,
  SetShippingAddressMutation,
  TransitionOrderToStateMutation,
  UpdateAccountMutation,
  UpdateOrderLineMutation,
} from '#framework/__generated__';
import {
  CartDocument,
  CartMetaDocument,
  ErrorCode,
  useActiveUserQuery,
  useAddItemMutation,
  useAddPaymentToOrderMutation,
  useApplyCouponMutation,
  useCartMetaQuery,
  useCartQuery,
  useCreateGuestCustomerMutation,
  useRemoveOrderLineMutation,
  useSetBillingAddressMutation,
  useSetOrderShippingMutation,
  useSetShippingAddressMutation,
  useTransitionOrderToStateMutation,
  useUpdateAccountMutation,
  useUpdateOrderLineMutation,
} from '#framework/__generated__';

type CartData = NonNullable<CartQuery['activeOrder']> &
  Partial<Pick<Order, 'payments'>>;
interface CartContextType {
  cart?: CartData;
  activeSellerToken?: string;
  eligibleShippingMethods: CartMetaQuery['eligibleShippingMethods'];
  nextOrderStates: CartMetaQuery['nextOrderStates'];
  loading?: boolean;
  mutating?: boolean;
  getLine(productVariantId: string): CartData['lines'][0] | undefined;
  addItem(
    productVariantId: string,
    quantity: number
  ): Promise<AddItemMutation['addItemToOrder'] | null>;
  updateLine(
    lineId: string,
    quantity: number
  ): Promise<UpdateOrderLineMutation['adjustOrderLine'] | null>;
  removeLine(
    lineId: string
  ): Promise<RemoveOrderLineMutation['removeOrderLine'] | null>;
  applyCoupon(
    code: string
  ): Promise<[boolean, ApplyCouponMutation['applyCouponCode'] | null]>;
  setCustomerDetails(
    customer: CreateCustomerInput
  ): Promise<
    | CreateGuestCustomerMutation['setCustomerForOrder']
    | UpdateAccountMutation['updateCustomer']
    | null
  >;
  setBillingAddress(
    address: CreateAddressInput
  ): Promise<SetBillingAddressMutation['setOrderBillingAddress'] | null>;
  setShippingAddress(
    address: CreateAddressInput
  ): Promise<SetShippingAddressMutation['setOrderShippingAddress'] | null>;
  setOrderShipping(
    shippingMethodId: string | null | undefined,
    deliveryTime: string,
    deliveryNotes: string
  ): Promise<SetOrderShippingMutation['setOrderShipping'] | null>;
  beginCheckout(): Promise<
    TransitionOrderToStateMutation['transitionOrderToState'] | null
  >;
  addPayment(
    input: PaymentInput
  ): Promise<AddPaymentToOrderMutation['addPaymentToOrder'] | null>;
  refetchCart(): Promise<CartData | null>;
  cancelOrder(): Promise<CartData | null>;
}

export class CartError extends Error {
  constructor(error: ErrorResult & { __typename: string }) {
    super(error.message);
    this.name = error.__typename;
  }
}

const CartContext = createContext<CartContextType>({
  loading: true,
} as unknown as CartContextType);

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const { data: userData } = useActiveUserQuery();
  const {
    data: cartData,
    loading,
    refetch,
  } = useCartQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: cartMetaData } = useCartMetaQuery({
    fetchPolicy: 'network-only',
    skip: !cartData?.activeOrder?.customFields?.deliveryTime,
  });
  const [mutating, setMutating] = useState(false);

  const updateCartCache = (
    cache: ApolloCache<NormalizedCache>,
    order?: { __typename: string } | DeepPartial<Order> | null
  ) => {
    if (!order || order.__typename !== 'Order') {
      throw new CartError(
        (order as { __typename: string } & ErrorResult) ?? {
          __typename: 'ErrorResult',
          errorCode: ErrorCode.UnknownError,
          message: 'Unknown error',
        }
      );
    }

    const oldQuery = cache.readQuery<CartQuery>({ query: CartDocument });
    const newOrder = order as Order;
    const lines = newOrder?.lines ?? oldQuery?.activeOrder?.lines ?? [];
    const parentLines = lines
      .filter((line) => !line.customFields?.parentOrderLine)
      .sort((a, b) =>
        a.productVariant.name.localeCompare(b.productVariant.name)
      );
    const childLines = lines
      .filter((line) => line.customFields?.parentOrderLine)
      .sort((a, b) =>
        a.productVariant.name.localeCompare(b.productVariant.name)
      );
    const sortedLines: OrderLine[] = [];
    for (const parentLine of parentLines) {
      sortedLines.push(parentLine);
      for (const childLine of childLines) {
        if (childLine.customFields?.parentOrderLine?.id === parentLine.id) {
          sortedLines.push(childLine);
        }
      }
    }
    cache.writeQuery<CartQuery>({
      query: CartDocument,
      data: {
        activeOrder: {
          ...(oldQuery?.activeOrder ?? {}),
          ...newOrder,
          lines: sortedLines,
        },
      },
    });

    // If the order has just been created, we need to refetch the cart query
    if (!oldQuery?.activeOrder && newOrder) {
      refetch().then(() => setMutating(false));
    } else {
      setMutating(false);
    }
  };

  const [addItemMutation] = useAddItemMutation({
    update: (cache, { data }) => updateCartCache(cache, data?.addItemToOrder),
  });
  const [updateOrderLineMutation] = useUpdateOrderLineMutation({
    update: (cache, { data }) => updateCartCache(cache, data?.adjustOrderLine),
  });
  const [removeOrderLineMutation] = useRemoveOrderLineMutation({
    update: (cache, { data }) => updateCartCache(cache, data?.removeOrderLine),
  });
  const [applyCouponMutation] = useApplyCouponMutation({
    update: (cache, { data }) => updateCartCache(cache, data?.applyCouponCode),
  });
  const [createGuestCustomerMutation] = useCreateGuestCustomerMutation({
    update: (cache, { data }) =>
      updateCartCache(cache, data?.setCustomerForOrder),
  });
  const [updateCustomerMutation] = useUpdateAccountMutation({
    update: (cache, { data }) =>
      updateCartCache(
        cache,
        data?.updateCustomer?.__typename === 'Customer'
          ? { __typename: 'Order', customer: data?.updateCustomer }
          : data?.updateCustomer
      ),
  });
  const [setOrderBillingAddressMutation] = useSetBillingAddressMutation({
    update: (cache, { data }) =>
      updateCartCache(cache, data?.setOrderBillingAddress),
  });
  const [setOrderShippingAddressMutation] = useSetShippingAddressMutation({
    refetchQueries: [{ query: CartMetaDocument }],
    update: (cache, { data }) =>
      updateCartCache(cache, data?.setOrderShippingAddress),
  });
  const [setOrderShippingMutation] = useSetOrderShippingMutation({
    refetchQueries: [{ query: CartMetaDocument }],
    update: (cache, { data }) => updateCartCache(cache, data?.setOrderShipping),
  });
  const [transitionOrderToStateMutation] = useTransitionOrderToStateMutation({
    update: (cache, { data }) =>
      updateCartCache(cache, data?.transitionOrderToState),
  });
  const [addPaymentToOrderMutation] = useAddPaymentToOrderMutation({
    update: (cache, { data }) =>
      updateCartCache(cache, data?.addPaymentToOrder),
  });

  const cartContextData: CartContextType = {
    cart: cartData?.activeOrder as Order,
    activeSellerToken:
      cartData?.activeOrder?.lines[0]?.productVariant?.seller?.token,
    eligibleShippingMethods: cartMetaData?.eligibleShippingMethods ?? [],
    nextOrderStates: cartMetaData?.nextOrderStates ?? [],
    loading,
    mutating,
    getLine: (productVariantId: string) =>
      cartData?.activeOrder?.lines.find(
        (line) => line.productVariant.id === productVariantId
      ),
    addItem: async (productVariantId, quantity) => {
      if (cartData?.activeOrder?.state === 'ArrangingPayment') {
        await transitionOrderToStateMutation({
          variables: { state: 'AddingItems' },
        });
      } else if (
        cartData?.activeOrder?.state &&
        cartData?.activeOrder?.state !== 'AddingItems'
      )
        return null;

      setMutating(true);

      return await addItemMutation({
        variables: {
          productVariantId: productVariantId,
          quantity,
        },
      }).then((result) => result.data?.addItemToOrder ?? null);
    },
    updateLine: async (lineId, quantity) => {
      if (!cartData?.activeOrder) return null;
      if (cartData?.activeOrder?.state === 'ArrangingPayment') {
        await transitionOrderToStateMutation({
          variables: { state: 'AddingItems' },
        });
      } else if (cartData?.activeOrder?.state !== 'AddingItems') return null;

      setMutating(true);

      return await updateOrderLineMutation({
        variables: {
          orderLineId: lineId,
          quantity,
        },
      }).then((result) => result.data?.adjustOrderLine ?? null);
    },
    removeLine: async (lineId) => {
      if (!cartData?.activeOrder) return null;
      if (cartData?.activeOrder?.state === 'ArrangingPayment') {
        await transitionOrderToStateMutation({
          variables: { state: 'AddingItems' },
        });
      } else if (cartData?.activeOrder?.state !== 'AddingItems') return null;

      setMutating(true);

      return await removeOrderLineMutation({
        variables: {
          orderLineId: lineId,
        },
      }).then((result) => result.data?.removeOrderLine ?? null);
    },
    applyCoupon: async (couponCode) => {
      if (!cartData?.activeOrder) return [false, null];
      if (cartData?.activeOrder?.state === 'ArrangingPayment') {
        await transitionOrderToStateMutation({
          variables: { state: 'AddingItems' },
        });
      } else if (cartData?.activeOrder?.state !== 'AddingItems')
        return [false, null];

      setMutating(true);

      return await applyCouponMutation({
        variables: {
          couponCode,
        },
      })
        .catch((e) => {
          if (e.networkError?.name) {
            return {
              data: {
                applyCouponCode: {
                  __typename: e.networkError?.name,
                },
              },
            };
          }
          return { data: null };
        })
        .then(
          (r) => (
            setMutating(false),
            [
              r.data?.applyCouponCode?.__typename === 'Order',
              r.data?.applyCouponCode ?? null,
            ]
          )
        );
    },
    setCustomerDetails: async (customer) => {
      if (!cartData?.activeOrder) return null;
      if (cartData?.activeOrder?.state === 'ArrangingPayment') {
        await transitionOrderToStateMutation({
          variables: { state: 'AddingItems' },
        });
      } else if (cartData?.activeOrder?.state !== 'AddingItems') return null;

      setMutating(true);

      if (userData?.me?.id) {
        return await updateCustomerMutation({
          variables: {
            input: customer,
          },
        }).then((result) => result.data?.updateCustomer ?? null);
      } else {
        return await createGuestCustomerMutation({
          variables: {
            input: customer,
          },
        }).then((result) => result.data?.setCustomerForOrder ?? null);
      }
    },
    setBillingAddress: async (address) => {
      if (!cartData?.activeOrder) return null;
      if (cartData?.activeOrder?.state === 'ArrangingPayment') {
        await transitionOrderToStateMutation({
          variables: { state: 'AddingItems' },
        });
      } else if (cartData?.activeOrder?.state !== 'AddingItems') return null;

      setMutating(true);

      return await setOrderBillingAddressMutation({
        variables: {
          input: address,
        },
      }).then((result) => result.data?.setOrderBillingAddress ?? null);
    },
    setShippingAddress: async (address) => {
      if (!cartData?.activeOrder) return null;
      if (cartData?.activeOrder?.state === 'ArrangingPayment') {
        await transitionOrderToStateMutation({
          variables: { state: 'AddingItems' },
        });
      } else if (cartData?.activeOrder?.state !== 'AddingItems') return null;

      setMutating(true);

      return await setOrderShippingAddressMutation({
        variables: {
          input: address,
        },
      }).then((result) => result.data?.setOrderShippingAddress ?? null);
    },
    setOrderShipping: async (
      shippingMethodId: string | null | undefined,
      deliveryTime: string,
      deliveryNotes: string
    ) => {
      if (!cartData?.activeOrder) return null;
      if (cartData?.activeOrder?.state === 'ArrangingPayment') {
        await transitionOrderToStateMutation({
          variables: { state: 'AddingItems' },
        });
      } else if (cartData?.activeOrder?.state !== 'AddingItems') return null;

      setMutating(true);

      return await setOrderShippingMutation({
        variables: {
          shippingMethodId,
          deliveryTime,
          deliveryNotes,
        },
      }).then((result) => result.data?.setOrderShipping ?? null);
    },

    beginCheckout: async () => {
      if (!cartData?.activeOrder) return null;
      if (cartData?.activeOrder?.state !== 'AddingItems') return null;

      setMutating(true);

      return await transitionOrderToStateMutation({
        variables: { state: 'ArrangingPayment' },
      }).then((result) => result.data?.transitionOrderToState ?? null);
    },

    addPayment: async (input: PaymentInput) => {
      if (!cartData?.activeOrder) return null;
      if (cartData?.activeOrder?.state === 'AddingItems') {
        const res = await transitionOrderToStateMutation({
          variables: { state: 'ArrangingPayment' },
        });

        if (res.data?.transitionOrderToState?.__typename !== 'Order') {
          return res.data;
        }
      } else if (cartData?.activeOrder?.state !== 'ArrangingPayment')
        return null;

      setMutating(true);

      return await addPaymentToOrderMutation({
        variables: { input },
      }).then((result) => result.data?.addPaymentToOrder ?? null);
    },

    refetchCart: async () => {
      setMutating(true);

      return await refetch().then(
        (result) => (setMutating(false), result.data?.activeOrder ?? null)
      );
    },

    cancelOrder: async () => {
      setMutating(true);

      return await transitionOrderToStateMutation({
        variables: { state: 'Cancelled' },
      }).then((result) => result.data?.transitionOrderToState ?? null);
    },
  };

  return (
    <CartContext.Provider value={cartContextData}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
