import cn from 'classnames';

import { Link } from '#components/ui/link';

export const Logo = ({ className }: { className?: string }) => {
  return (
    <Link href='/' className={cn('inline-flex', className)}>
      <span className='overflow-hidden relative w-32 md:w-40 h-10 object-contain'>
        <span className='sr-only'>TUGO</span>
        <svg
          version='1.0'
          width='100%'
          height='100%'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 422.000000 128.000000'
          preserveAspectRatio='xMidYMid meet'
        >
          <g
            transform='translate(0.000000,128.000000) scale(0.100000,-0.100000)'
            fill='#2EA000'
            stroke='none'
          >
            <path d='M3677 1263 c-201 -34 -348 -171 -388 -362 -16 -77 -6 -213 19 -280 73 -189 228 -292 437 -292 233 0 399 128 451 348 52 218 -33 432 -209 531 -101 57 -197 74 -310 55z m206 -101 c204 -90 234 -519 48 -689 -73 -68 -207 -88 -301 -46 -52 23 -123 91 -151 147 -49 93 -58 279 -19 390 39 113 126 197 222 215 64 11 154 4 201 -17z' />
            <path d='M35 1260 c-1 -3 -5 -39 -9 -80 -4 -41 -9 -99 -12 -128 l-6 -53 33 3 c30 3 33 6 49 66 27 97 34 102 156 102 l103 0 7 -37 c10 -51 -3 -704 -14 -715 -5 -5 -36 -14 -70 -20 -60 -11 -80 -27 -65 -51 6 -9 70 -12 221 -12 l212 0 0 26 c0 23 -5 26 -65 38 -36 7 -68 17 -71 22 -5 9 -9 481 -5 672 l1 78 112 -3 c128 -3 123 0 154 -110 14 -54 17 -58 46 -58 l30 0 -6 98 c-4 53 -9 112 -12 129 l-5 33 -390 2 c-215 2 -392 1 -394 -2z' />
            <path d='M2945 1215 c-316 -85 -577 -293 -645 -512 -59 -192 28 -346 162 -285 23 11 105 67 181 126 76 58 140 106 142 106 12 0 2 -21 -50 -108 -107 -181 -150 -237 -215 -281 -61 -42 -113 -93 -137 -135 -18 -30 -16 -82 4 -101 40 -41 113 11 228 164 33 43 82 94 109 112 154 105 276 210 276 237 0 12 -2 22 -5 22 -3 0 -48 -30 -101 -66 -52 -37 -97 -64 -100 -61 -3 2 26 56 64 118 154 253 175 307 136 336 -35 25 -62 15 -122 -45 -113 -114 -340 -295 -410 -326 -41 -19 -42 -19 -63 0 -25 23 -21 56 17 129 57 108 168 220 311 312 74 48 190 99 201 89 4 -5 -10 -23 -30 -41 -43 -37 -50 -60 -15 -49 56 17 179 94 201 126 27 38 17 117 -18 140 -16 11 -65 8 -121 -7z' />
            <path d='M1263 1193 l-63 -4 -1 -32 c-4 -111 4 -484 12 -542 12 -88 57 -184 107 -225 81 -67 268 -86 373 -38 77 36 114 70 148 141 42 86 50 154 54 445 l3 252 -65 0 -66 0 2 -262 c3 -276 -3 -334 -40 -408 -28 -57 -73 -87 -142 -95 -81 -11 -147 9 -188 55 -64 70 -74 142 -69 478 1 133 2 241 0 240 -2 -1 -31 -3 -65 -5z' />
          </g>
        </svg>
      </span>
    </Link>
  );
};
