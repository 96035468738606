import loadable from '@loadable/component';
import cn from 'classnames';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { Logo } from '#components/ui/logo';
//import { useActiveUserQuery } from '#framework/__generated__';
import { useDeviceType } from '#lib/use-device-type';
import { useIsGrocerHome } from '#lib/use-is-grocer-home';
import { useIsHomepage } from '#lib/use-is-homepage';
import {
  displayHeaderSearchAtom,
  displayMobileHeaderSearchAtom,
} from '#store/atoms';

import styles from './header.module.css';
//import { JoinButton } from './menu/join-button';
import { StaticMenu } from './menu/static-menu';
//import { UserMenu } from './menu/user-menu';

const Search = loadable(() => import('#components/ui/search/search'), {
  resolveComponent: (module) => module.Search,
});

export const Header = () => {
  const { t } = useTranslation('common');
  //const { data } = useActiveUserQuery();
  const [displayHeaderSearch] = useAtom(displayHeaderSearchAtom);
  const [displayMobileHeaderSearch] = useAtom(displayMobileHeaderSearchAtom);
  const isHomepage = useIsHomepage();
  const isGrocerHome = useIsGrocerHome();
  const deviceType = useDeviceType();

  const isTransparentHeader =
    !displayHeaderSearch && isHomepage && deviceType === 'desktop'; //!displayHeaderSearch && layout !== 'modern';

  return (
    <header
      className={cn('site-header-with-search h-14 md:h-16 lg:h-22 lg:w-full', {
        'lg:!h-auto': isTransparentHeader,
      })}
    >
      <div
        className={cn(
          'flex justify-between items-center w-full h-14 md:h-16 lg:h-22 px-4 lg:px-8 py-5 z-50 fixed border-b border-border-200 shadow-sm transition-transform duration-300',
          {
            'bg-light': !isTransparentHeader,
            'lg:absolute lg:ssssbg-transparent lg:shadow-none lg:border-0':
              isTransparentHeader,
            [styles['header-gradient']]: isTransparentHeader,
          }
        )}
      >
        <div className='flex items-center w-full lg:w-auto'>
          <Logo className='mx-auto lg:mx-0' />

          {/*<div className='ms-10 mr-auto hidden xl:block'>
            <GroupsDropdownMenu />
          </div>*/}
        </div>
        {isHomepage || isGrocerHome ? (
          <>
            {displayHeaderSearch && (
              <div className='hidden lg:block w-full xl:w-11/12 2xl:w-10/12 mx-auto px-10 overflow-hidden'>
                <Search label={t('text.search')} variant='minimal' />
              </div>
            )}

            {displayMobileHeaderSearch && (
              <div className='block lg:hidden w-full absolute top-0 left-0 h-full bg-light pt-1.5 md:pt-2 px-5'>
                <Search label={t('text.search')} variant='minimal' />
              </div>
            )}
          </>
        ) : null}
        <ul
          className={cn(
            'hidden lg:flex items-center flex-shrink-0 gap-10',
            styles['ts-white']
          )}
        >
          <StaticMenu />
          {/*<li>{data?.me?.identifier ? <UserMenu /> : <JoinButton />}</li>*/}
        </ul>
      </div>
    </header>
  );
};
