import cn from 'classnames';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { navigate } from 'vite-plugin-ssr/client/router';

import { HomeIcon } from '#components/icons/home-icon';
import { NavbarIcon } from '#components/icons/navbar-icon';
import { SearchIcon } from '#components/icons/search-icon';
import { ShoppingBagIcon } from '#components/icons/shopping-bag-icon';
//import { UserIcon } from '#components/icons/user-icon';
//import { Avatar } from '#components/ui/avatar';
import { useDrawerAction } from '#components/ui/drawer/drawer.context';
import { useModalAction } from '#components/ui/modal/modal.context';
import { useActiveUserQuery } from '#framework/__generated__';
import { useIsGrocerHome } from '#lib/use-is-grocer-home';
import { useIsHomepage } from '#lib/use-is-homepage';
import { displayMobileHeaderSearchAtom } from '#store/atoms';
import { useCart } from '#store/cart.context';
import { usePageContext } from '#store/page.context';

export const MobileNav = () => {
  const { t } = useTranslation('common');
  const { cart } = useCart();
  const { data } = useActiveUserQuery();
  const isHomepage = useIsHomepage();
  const isGrocerHome = useIsGrocerHome();
  const { openModal } = useModalAction();
  const [displayMobileHeaderSearch, setDisplayMobileHeaderSearch] = useAtom(
    displayMobileHeaderSearchAtom
  );
  const { openDrawer } = useDrawerAction();
  const { urlParsed } = usePageContext();

  const handleHomeLink = () => {
    setDisplayMobileHeaderSearch(false);
    navigate('/');
  };

  return (
    <div className='visible lg:hidden h-12 md:h-14'>
      <nav className='h-14 md:h-16 w-full py-1.5 px-2 flex justify-between fixed left-0 bottom-0 z-10 bg-light shadow-400'>
        <motion.button
          whileTap={{ scale: 0.88 }}
          onClick={() => openDrawer('MAIN_MENU')}
          className='flex p-2 h-full items-center justify-center focus:outline-none focus:text-accent'
        >
          <span className='sr-only'>{t('sr.main-menu')}</span>
          <NavbarIcon style={{ scale: '120%' }} />
        </motion.button>

        {(isHomepage || isGrocerHome) && (
          <motion.button
            whileTap={{ scale: 0.88 }}
            onClick={() =>
              setDisplayMobileHeaderSearch(!displayMobileHeaderSearch)
            }
            className={cn(
              'flex p-2 h-full items-center justify-center focus:outline-none',
              {
                'text-accent': displayMobileHeaderSearch,
              }
            )}
          >
            <span className='sr-only'>{t('sr.search')}</span>
            <SearchIcon width='17.05' height='18' />
          </motion.button>
        )}

        <motion.button
          whileTap={{ scale: 0.88 }}
          onClick={handleHomeLink}
          className={cn(
            'flex p-1 h-full items-center justify-center focus:outline-none focus:text-accent',
            {
              'text-accent': isHomepage && !displayMobileHeaderSearch,
            }
          )}
        >
          <span className='sr-only'>{t('sr.home')}</span>
          <HomeIcon style={{ scale: '120%' }} />
        </motion.button>

        <motion.button
          whileTap={{ scale: 0.88 }}
          onClick={() => openDrawer('CART_SIDEBAR')}
          className='flex p-2 product-cart h-full relative items-center justify-center focus:outline-none focus:text-accent'
        >
          <span className='sr-only'>{t('sr.cart')}</span>
          <ShoppingBagIcon style={{ scale: '120%' }} />
          {!!cart?.lines.length && (
            <motion.span
              className='bg-accent py-1 px-1.5 text-10px leading-none font-semibold text-light rounded-full absolute top-0 right-0 mt-0.5 -mr-0.5'
              animate={
                urlParsed.pathname === '/kasse'
                  ? {}
                  : { opacity: [1, 0.2, 1, 0.2, 1] }
              }
              transition={{
                repeat: Infinity,
                duration: 0.75,
                damping: 10,
                delay: 5,
                repeatDelay: 5,
                repeatType: 'loop',
              }}
            >
              {cart?.lines.length}
            </motion.span>
          )}
        </motion.button>

        {/*data?.me?.id ? (
          <motion.button
            whileTap={{ scale: 0.88 }}
            onClick={() => openDrawer('USER_MENU')}
            className='flex p-2 h-full items-center justify-center focus:outline-none focus:text-accent'
          >
            <span className='sr-only'>{t('sr.user')}</span>
            <Avatar />
          </motion.button>
        ) : (
          <motion.button
            whileTap={{ scale: 0.88 }}
            onClick={() => openModal('LOGIN_VIEW')}
            className='flex p-2 h-full items-center justify-center focus:outline-none focus:text-accent'
          >
            <span className='sr-only'>{t('sr.user')}</span>
            <UserIcon />
          </motion.button>
        )*/}
      </nav>
    </div>
  );
};
