import { useTranslation } from 'react-i18next';

import { Link } from '#components/ui/link';

import { menuItems } from './static-menu-items';

export const StaticMenu = () => {
  const { t } = useTranslation('common');

  return (
    <>
      {menuItems.map(({ label, path }) => (
        <li key={path}>
          <Link
            href={path}
            activeMatch={path}
            className='font-semibold text-heading flex items-center transition duration-200 no-underline hover:text-accent focus:text-accent'
            activeClassName='!text-accent'
          >
            {t(label)}
          </Link>
        </li>
      ))}
    </>
  );
};
