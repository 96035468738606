import { atom } from 'jotai';

export const checkoutAtom = atom({
  contactValid: false,
  billingValid: false,
  shippingValid: false,
  deliveryValid: false,
  paymentSecret: '',
});

export const displayHeaderSearchAtom = atom(false);
export const displayMobileHeaderSearchAtom = atom(false);
